@use 'colors.module';

.reading {
  width: 100%;
  //max-width: 94vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  padding: 0.5% 2.5%;

  &.full {
    > div {
      max-width: 90vw;
    }
  }

  > div {
    min-width: 50vw;
    max-width: 70vw;
  }
}

.overflowWrapper {
  width: 70%;
  overflow-x: auto;
  margin: auto;
}

.codeWrapper {
  overflow-x: auto;
  margin: auto;
  border-radius: 2px;
}

.mrkdImageBox {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-content: center;
}

.mrkdImage {
  display: block;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  padding: 2%;
}

// ------------------------------ Table
.mrdkTableWrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2em 0;
}

.mrkdTable {
  max-width: 85%;
  overflow-x: auto;

  &.light {
    tbody {
      background-color: colors.$textBlend;
    }

    tr:nth-child(2n + 1) {
      background-color: colors.$contrastLight;
    }

    th {
      background-color: colors.$lightHighlight;
    }
  }

  &.dark {
    tbody {
      background-color: colors.$lightSlateGrey;
    }

    tr:nth-child(2n + 1) {
      background-color: colors.$contrastDark;
    }

    th {
      background-color: colors.$darkHighlight;
    }
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0 1em;
    text-align: center;
  }
}

// --------- Horizontal Rule
.rule {
  border: 0;
  border-radius: 2px;
  height: 2px;
  width: 100%;

  &.left,
  &.right {
    width: 15% !important;
  }

  &.left {
    margin-right: 85%;
  }

  &.right {
    margin-left: 85%;
  }

  &.light {
    background-image: radial-gradient(
      ellipse at center,
      colors.$textLight 0%,
      colors.$lightHighlight 100%
    );
  }

  &.light.left {
    background-image: linear-gradient(
      to right,
      colors.$textLight,
      colors.$lightHighlight
    ) !important;
  }

  &.light.right {
    background-image: linear-gradient(
      to left,
      colors.$textLight,
      colors.$lightHighlight
    ) !important;
  }

  &.dark {
    background-image: radial-gradient(
      ellipse at center,
      colors.$textDark 0%,
      colors.$darkHighlight 100%
    );
  }

  &.dark.left {
    background-image: linear-gradient(
      to right,
      colors.$textDark,
      colors.$darkHighlight
    ) !important;
  }

  &.dark.right {
    background-image: linear-gradient(
      to left,
      colors.$textDark,
      colors.$darkHighlight
    ) !important;
  }
}

.banner {
  width: 100%;
  height: 12rem;
  position: relative;
  z-index: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.banner > img {
  width: 100%;
  height: 12rem;
  object-fit: cover;
}

.banner > .time {
  position: absolute;
  bottom: -0.3rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
