import React, { useEffect } from 'react';
import * as style from '../../styles/markdown.module.scss';
import * as typography from '../../styles/typography.module.scss';
import PropTypes from 'prop-types';
import Gist from 'react-gist';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';
import { scrollToTop } from '../../util/domUtils';

export const GistView = ({ id, title, date }) => {
  const theme = useRecoilValue(themeAtom);

  useEffect(() => scrollToTop(), []);

  return (
    <div className={style.reading}>
      <section className={style.overflowWrapper}>
        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h3>{title}</h3>

          <h5>{moment(date).format('MMM Do, YYYY')}</h5>
        </section>

        <Gist id={id} />
      </section>
    </div>
  );
};

GistView.propTypes = {
  id: PropTypes.string.isRequired,
};

GistView.defaultProps = {
  id: undefined,
};
