import React from 'react';
import styled from 'styled-components';
import { Typography, Link, useTheme } from '@material-ui/core';

const StyledLink = styled(Link)`
  color: ${({ logoColor }) => logoColor};
  text-decoration: none;
  display: inline-block;
  line-height: 0.85;
  target-new: tab;

  :hover {
    cursor: pointer;
  }

  :active {
    color: black;
  }

  :visited {
    color: ${({ logoColor }) => logoColor};
    text-decoration: none;
  }

  ::selection {
    color: ${({ logoColor }) => logoColor};
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 1.2rem;
  }
`;

//todo -> make this use my style
export const MarkdownLink = ({ children, href }) => {
  const { palette } = useTheme();

  return (
    <span>
      <StyledTypography display={'inline'} variant={'body1'} color={'info'}>
        <StyledLink
          href={href}
          target={'_blank'}
          color={'info'}
          logoColor={palette.info.main}>
          {children}
        </StyledLink>
      </StyledTypography>
    </span>
  );
};
