//@flow
import { Uses, About, Credits, Error, BlogDirector, Launch } from './pages';
import { MarkdownReader } from './components/read/MarkdownReader';
import type { Route } from './types';

export const _routes: Array<Route> = [
  {
    route: '/',
    component: Launch,
    name: 'Home',
  },
  {
    route: '/reading',
    name: 'Reads',
    nested: [
      {
        path: '/latest',
        name: 'Latest',
        component: MarkdownReader,
      },
      {
        path: '/:type/:slug/:title?',
        component: MarkdownReader,
      },
      {
        path: '/shelf',
        name: 'Grid',
        component: BlogDirector,
      },
    ],
  },
  {
    route: '/about',
    component: About,
    name: `About`,
  },
  /*  {
    route: '/contactMe',
    component: undefined,
    name: `Contact Me`
  },*/
  {
    route: '/uses',
    component: Uses,
    name: `Uses`,
  },
  {
    route: '/credit',
    component: Credits,
    name: `Credit`,
  },
  {
    route: '/error',
    component: Error,
  },
  {
    route: '*',
    component: Error,
  },
];
