@use 'colors.module';

$position: center 6rem;
$positionAlt: center 4.7rem;

.details {
  width: 100%;
  height: 100%;
  padding: 1% 0;
  margin: 1% 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;

  &.cardLight {
    background-color: colors.$lightGrey;
  }

  &.cardDark {
    background-color: colors.$darkGrey;
  }

  &.lCorner {
    clip-path: polygon(0% 0%, 0% 100%, 85% 100%, 100% 70%, 100% 0%);
  }

  &.rCorner {
    clip-path: polygon(0% 0%, 0% 85%, 20% 100%, 100% 100%, 100% 0%);
  }

  &:hover {
    cursor: pointer;
  }

  &.regHover {
    &:hover {
      background-color: rgba(colors.$textBlend, 0.5);
    }
  }

  &.coolHover {
    transition: margin 0.2s ease-in-out;

    &:hover {
      margin-bottom: 15px;
    }
  }
}

.wrapper {
  width: 20rem;
  height: calc(25rem / 2.8);
  display: flex;
  flex-flow: row nowrap;
  clip-path: polygon(0% 0%, 95% 0%, 100% 10%, 100% 100%, 5% 100%, 0% 90%);
}

.left {
  width: 22%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat, repeat;
  background-size: 4rem, 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

  &.dev-life {
    background-color: colors.$blog-purple;
    background-position: $positionAlt;
    background-image: url('../assets/innovation.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.blog-orange {
    background-color: colors.$blog-orange;
    background-position: $positionAlt;
    background-image: url('../assets/innovation.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.js-yellow {
    background-color: colors.$js-yellow;
    background-position: $position;
    background-image: url('../assets/javascript-vertical.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.java-red {
    background-color: colors.$java-red;
    background-position: $positionAlt;
    background-image: url('../assets/java-icon.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.spring-green {
    background-color: colors.$spring-green;
    background-position: $positionAlt;
    background-image: url('../assets/springio-icon.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.my-blue {
    background-color: colors.$my-blue;
    background-position: $positionAlt;
    background-image: url('../assets/reactjs-icon.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.github-black {
    background-color: colors.$github-black;
    background-position: $positionAlt;
    background-image: url('../assets/github-tile.svg'),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;

  &.light {
    background-color: colors.$contrastLight;
  }

  &.dark {
    background-color: colors.$contrastDark;
  }
}
