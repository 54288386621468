@use 'colors.module';

.typeface h1,
h2,
h3,
h4,
h5,
h6,
p,
time,
code,
span,
body {
  font-family: 'Victor Mono', serif;
  font-weight: normal;
  margin: 0;
}

.typeface {
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  white-space: pre-line;
  word-wrap: break-word;

  &.line {
    display: inline;
    width: auto;

    & > p {
      display: inline;
    }
  }

  &.gutter {
    margin-bottom: 3%;
  }

  &.italics {
    font-style: italic;
  }

  &.edgeGuard {
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &.edgeRight {
    width: calc(100% - 0.5rem);
    margin-right: 0.5rem;
  }

  &.edgeLeft {
    width: calc(100% - 0.5rem);
    margin-left: 0.5rem;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }

  &.caps {
    text-transform: capitalize;
  }

  &.lower {
    text-transform: lowercase;
  }

  &.upper {
    text-transform: uppercase;
  }

  &.bold {
    font-weight: bold;
  }

  &.inline {
    overflow: hidden;
    white-space: nowrap;
  }

  &.underline {
    border-bottom: 2px solid currentColor;
    display: inline-block;
  }

  &.markdown {
    & > code {
      padding: 0 0.5em;
      font-weight: 500;
    }
  }

  &.action {
    cursor: pointer;
  }

  &.light {
    color: colors.$textLight;

    ::selection {
      color: colors.$lightHighlight;
    }

    & > code {
      color: colors.$textAltLight;
    }
  }

  &.shift {
    color: colors.$textBlend;
    mix-blend-mode: difference;
  }

  &.dark {
    color: colors.$textDark;

    ::selection {
      color: colors.$darkHighlight;
    }

    & > code {
      color: colors.$textAltDark;
    }
  }
}

.typeface > .action {
  cursor: pointer;
}

.typeface > h1 {
  font-size: clamp(3.9rem, 75%, 4.7rem);
}

.typeface > h2 {
  font-size: clamp(2.9rem, 70%, 4.5rem);
}

.typeface > h3 {
  font-size: clamp(2.2rem, 67%, 3rem);
}

.typeface > h4 {
  font-size: clamp(1.9rem, 50%, 2.7rem);
}

.typeface > h5 {
  font-size: clamp(1.7rem, 50%, 2.5rem);
}

.typeface > h6 {
  font-size: clamp(1.5rem, 50%, 2.3rem);
}

.typeface > .subtitleOne {
  font-size: clamp(1.2rem, 85%, 1.5rem);
  font-weight: 400;
}

.typeface > .subtitleTwo {
  font-size: clamp(1.2rem, 50%, 1.5rem);
  font-weight: 400;
}

.typeface > p,
body {
  font-size: clamp(1.5rem, 90%, 1.8rem);
}

.typeface > .button {
  text-transform: uppercase !important;
  font-size: clamp(1.5rem, 90%, 1.8rem);
}

.typeface > .tableFace {
  thead > tr > th {
    font-weight: 600;
    font-size: clamp(1.4rem, 85%, 1.7rem);
  }

  tbody > tr > td {
    font-weight: 400;
    font-size: clamp(1.2rem, 80%, 1.5rem);
  }
}
