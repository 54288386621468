import React from 'react';
import * as style from '../../styles/squarespinner.module.scss';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';

export const SquareSpinner = () => {
  const theme = useRecoilValue(themeAtom);

  return (
    <div className={style.container}>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
      <div className={`${style.block} ${style[theme]}`}></div>
    </div>
  );
};
