const size = {
  smartphone_port_land:
    '(min-device-width :320px) and (max-device-width : 480px)',
  smartphone_port: '(min-width : 320px)',
  smartphone_land: '(min-width : 321px)',
  ipad_port_land: '(min-device-width : 768px) and (max-device-width : 1024px)',
  ipad_port:
    '(min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)',
  ipad_land:
    '(min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)',
  ipad3_land:
    '(min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)',
  ipad3_port:
    '(min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)',
  desktop: '(min-width : 1224px)',
  desktop_med: '(min-width: 1440px)',
  desktop_lrg: '(min-width : 1824px)',
  iphone4_land:
    '(min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)',
  iphone4_port:
    '(min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)',
  iphone5_land:
    '(min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)',
  iphone5_port:
    '(min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)',
  iphone678_land:
    '(min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)',
  iphone678_port:
    '(min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)',
  iphone678_plus_land:
    '(min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)',
  iphone678_plus_port:
    '(min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)',
  iphone_x_land:
    '(min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3)',
  iphone_x_port:
    '(min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3)',
  iphone_xs_max_xr_land:
    '(min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3)',
  iphone_xs_max_xr_port:
    '(min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3)',
};

export const media = Object.keys(size).reduce((acc, key) => {
  acc[key] = style => `
        @media only screen and ${size[key]} {
          ${style};
        }
      `;
  return acc;
}, {});
