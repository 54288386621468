/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as style from '../styles/uses.module.scss';
import * as mrkdn from '../styles/markdown.module.scss';
import { useFirebaseDatabase, useFirebaseCloudStorage } from '../hooks';
import { MarkdownDisplay } from '../components/markdown/MarkdownDisplay';
import { Loading } from '../components/read/Loading';

export const Uses = () => {
  const { uses } = useFirebaseDatabase(['uses'], true);
  const { markdown } = useFirebaseCloudStorage('uses', 'uses', true);

  return (
    <div className={style.usesStyle}>
      <section className={mrkdn.banner}>
        <img src={uses.banner} alt={''} />
      </section>

      {markdown ? (
        <section className={style.mrkd}>
          <MarkdownDisplay markdown={markdown} />
        </section>
      ) : (
        <Loading />
      )}
    </div>
  );
};
