import React, { useEffect, useState } from 'react';
import * as style from '../styles/blogDirector.module.scss';
import '../styles/containers.scss';
import { useFirebaseDatabase } from '../hooks';
import { slugify, sortByDate } from '../util/readUtil';
import { Details } from '../components/read/Details';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../state/atoms';
import { AnimateGroup } from '../components/animation/AnimateGroup';

export const BlogDirector = () => {
  const theme = useRecoilValue(themeAtom);

  const [go, setGo] = useState(false);

  const { reads, gists, tips } = useFirebaseDatabase(
    ['reads', 'tips', 'gists'],
    true,
  );

  useEffect(() => setGo(true), []);

  return (
    <div className={style.directorStyle}>
      <AnimateGroup
        className={'grid-wrapper'}
        trigger={go}
        animations={['fade', 'stagger']}>
        {sortByDate([...reads, ...gists, ...tips]).map(
          ({ slug, type, chip, date }) => (
            <div className={`${theme}`}>
              <Details
                title={slugify(slug)}
                orientation={'full'}
                toUrl={{ type, slug }}
                date={date}
              />
            </div>
          ),
        )}
      </AnimateGroup>
    </div>
  );
};
