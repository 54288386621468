/*eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import * as style from '../styles/launch.module.scss';
import * as typography from '../styles/typography.module.scss';
import { useFirebaseDatabase } from '../hooks';
import { StackIcon } from '../components/read/StackIcon';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../state/atoms';
import { FilteredBackground } from '../components/theme/FilteredBackground';

export const Launch = () => {
  const inc = 250;
  const theme = useRecoilValue(themeAtom);
  const { chips } = useFirebaseDatabase(['chips'], true);

  return (
    <div className={style.launchStyle}>
      <section className={style.textWrapper}>
        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h4>
            Hello, My name is <i>Branden Boyington</i>
          </h4>
        </section>

        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h5>
            I'm a full stack developer from Southaven Mississippi and I write
            about...
          </h5>
        </section>
      </section>

      <FilteredBackground>
        <section className={style.stacks}>
          {chips.length > 1 &&
            chips
              .filter(e => e.title.toLowerCase() !== 'gist')
              .map((el, index) => (
                <StackIcon
                  key={index}
                  color={el.color}
                  title={el.title}
                  size={'medium'}
                  delay={inc + inc * (index + 1)}
                />
              ))}
        </section>
      </FilteredBackground>

      <section className={style.textWrapper}>
        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h5>
            If you like learning about any of these things then stick around.
          </h5>
        </section>
      </section>
    </div>
  );
};
