//@flow
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { config, headings, themeAtom } from '../../state/atoms';
import '../../styles/dropMenu.scss';
import * as typography from '../../styles/typography.module.scss';
import { moveTo } from '../../util/domUtils';
import { useLocation } from 'react-router-dom';
import { AnimateGroup } from '../animation/AnimateGroup';

interface Props {
  labels?: Array<{
    title: string,
    action?: () => {},
    options: Array<{ title: string, action: () => {} }>,
  }>;
}

export const DropMenu = ({ labels }: Props) => {
  const { pathname } = useLocation();

  //? Application State
  const [conf, setConf] = useRecoilState(config);
  const theme = useRecoilValue(themeAtom);
  const jumps = useRecoilValue(headings);
  const { fullScreenReader } = useRecoilValue(config);

  //? Component State
  const [opts, setOpts] = useState([]);

  const needJumps = () => pathname.indexOf('/reading') >= 0 && fullScreenReader;

  useEffect(async () => {
    await setOpts([]);
    await setOpts([
      ...labels,
      needJumps() && {
        title: 'Sections',
        options: jumps.map(({ id, text }) => ({
          title: text,
          action: () => moveTo(id[0] === '_' ? id.slice(1) : id),
        })),
      },
    ]);
  }, [labels, jumps]);

  return (
    <nav role={'navigation'}>
      <ul>
        <AnimateGroup trigger={opts.length > 0} animations={['fade', 'up']}>
          {opts.map(({ title, action, options }) => (
            <li className={theme} onClick={action ? action : () => {}}>
              <section
                className={`${typography.typeface} ${typography.upper} ${typography[theme]}`}>
                <h5>{title}</h5>
              </section>

              {options && (
                <ul className={`dropdown ${theme}`}>
                  {options.map(({ title, action }) => (
                    <li onClick={action} className={theme}>
                      <section
                        className={`${typography.typeface} ${typography.upper} ${typography[theme]}`}>
                        <span className={typography.subtitleTwo}>{title}</span>
                      </section>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </AnimateGroup>
      </ul>
    </nav>
  );
};
