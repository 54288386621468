//@flow
import React from 'react';
import * as style from '../../styles/details.module.scss';
import * as typography from '../../styles/typography.module.scss';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';
import * as mdStyle from '../../styles/markdown.module.scss';
import { moveTo } from '../../util/domUtils';

interface Props {
  +title: string;
  +date?: string;
  +last?: boolean;
  +orientation?: 'full' | 'left' | 'right' | 'center';
  +toUrl?: { type: string, slug: string };
  +toSection?: { id: Array<any> };
  +isCard?: boolean;
}

export const Details = ({
  title,
  date,
  last,
  orientation,
  toUrl,
  toSection,
  isCard = false,
}: Props) => {
  const history = useHistory();

  // application state
  const theme = useRecoilValue(themeAtom);

  const fontSetting = `${typography.typeface} ${typography[theme]} ${
    orientation === 'full'
      ? typography.center
      : orientation === 'left'
      ? `${typography.left} ${typography.edgeLeft}`
      : `${typography.right} ${typography.edgeRight}`
  } `;

  const navigate = () => {
    if (!toUrl && !toSection) return;

    if (toUrl) {
      const { type, slug } = toUrl;
      history.push(`/reading/${type}/${slug}`);
    }

    if (toSection) {
      const { id } = toSection;
      moveTo(id[0] === '_' ? id.slice(1) : id);
    }
  };

  return (
    <div
      className={`
      ${style.details} ${style[theme]}
      ${
        !isCard
          ? undefined
          : theme === 'light'
          ? style.cardLight
          : style.cardDark
      }
      ${orientation === 'left' && style.lCorner}
      ${orientation === 'right' && style.rCorner}
      ${['left', 'right'].includes(orientation) && style.regHover}
      ${['center', 'full'].includes(orientation) && style.coolHover}
      `}
      onClick={navigate}>
      <section className={fontSetting}>
        <span
          className={
            orientation === 'full'
              ? typography.subtitleOne
              : typography.subtitleTwo
          }>
          {title}
        </span>

        {date && <br />}

        {date && (
          <span className={typography.subtitleTwo}>
            {moment(date).format('MMM Do, YYYY')}
          </span>
        )}
      </section>

      {!['full', 'center'].includes(orientation) && !last && (
        <hr
          className={`${mdStyle.rule} ${mdStyle[theme]} ${
            mdStyle[orientation === 'left' ? 'left' : 'right']
          }`}
        />
      )}
    </div>
  );
};
