import React from 'react';
import { notfound } from '../assets';
import '../styles/error.scss';
import * as typography from '../styles/typography.module.scss';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { config, themeAtom } from '../state/atoms';

/**
 * Used for page errors and api errors
 * @returns {JSX.Element}
 * @constructor
 */
export const Error = () => {
  const { state } = useLocation();
  const conf = useRecoilValue(config);
  const theme = useRecoilValue(themeAtom);

  return (
    <div className={'notFoundWrapper'}>
      <section
        className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
        <h5>
          {state
            ? `Oh boy, something isn't quite right here...`
            : `Uhhh there's something off here...`}
        </h5>
      </section>

      <section className={`imgBox ${conf.isMobile && 'small'}`}>
        <img src={notfound} alt={'not found'} />
      </section>

      <section
        className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
        <h5>
          {state
            ? state.error
            : `...I don't think the page you asked for exists!`}
        </h5>
      </section>
    </div>
  );
};
