/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as typography from '../styles/typography.module.scss';
import * as typewriter from '../styles/typewriter.module.scss';
import * as style from '../styles/about.module.scss';
import * as mrkdn from '../styles/markdown.module.scss';
import { useFirebaseDatabase, useFirebaseCloudStorage } from '../hooks';
import { MarkdownDisplay } from '../components/markdown/MarkdownDisplay';
import { Loading } from '../components/read/Loading';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../state/atoms';
import { cross } from '../assets';

export const About = () => {
  const theme = useRecoilValue(themeAtom);
  const { about } = useFirebaseDatabase(['about'], true);
  const { markdown } = useFirebaseCloudStorage('about', 'about', true);
  const heap = ['React', 'Spring Framework', 'PostgreSql', 'Docker'];

  return (
    <div className={style.aboutStyle}>
      <section className={mrkdn.banner}>
        <img src={about.banner} alt={''} />
      </section>

      <section className={style.title}>
        <section className={style.crossWrapper}>
          <img
            className={`${style.crossStyle} ${style[theme]}`}
            src={cross}
            alt={''}
          />
        </section>

        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h3
            className={`${typewriter.line1} ${typewriter[theme]} ${typewriter.animTypewriter}`}>
            My name is Branden Boyington.
          </h3>
        </section>

        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h4>I'm a fullstack developer who works with</h4>
        </section>

        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
          <h5>
            <span className={`${style.highlight} ${style[theme]}`}>[</span>
            {heap.toString()}
            <span className={`${style.highlight} ${style[theme]}`}>]</span>
          </h5>
        </section>
      </section>

      {markdown ? (
        <section className={style.mrkd}>
          <MarkdownDisplay markdown={markdown} />
        </section>
      ) : (
        <Loading />
      )}
    </div>
  );
};
