/*eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import * as style from '../../styles/app.module.scss';
import { Route, Switch } from 'react-router-dom';
import { _routes } from '../../routes';
import { useRecoilValue } from 'recoil';
import { Nav } from '../nav/Nav';
import { Footer } from '../nav/Footer';
import { config, themeAtom } from '../../state/atoms';
import { ErrorRedirect } from '../nav/ErrorRedirect';
import '@animxyz/core';
import { initializeApp } from 'firebase/app';

const App = () => {
  const theme = useRecoilValue(themeAtom);
  const { firebase } = useRecoilValue(config);

  initializeApp(firebase);

  return (
    <div className={`${style.appStyle} ${style[theme]}`}>
      <ErrorRedirect />

      <Nav />

      <Switch>
        {_routes.map(({ route, component, nested }, index) =>
          nested ? (
            <Route
              key={`parent_${index}`}
              path={route}
              component={component || undefined}>
              {nested.map(({ path, component }, idx) => (
                <Route
                  key={`nested_${idx}`}
                  path={`${route}${path}`}
                  component={component}
                />
              ))}
            </Route>
          ) : (
            <Route
              key={index}
              exact={route === '/'}
              path={route}
              component={component}
            />
          ),
        )}
      </Switch>

      <Footer />
    </div>
  );
};

export default App;
