import React from 'react';
import * as typography from '../../../styles/typography.module.scss';
import styled from 'styled-components';
import { Typography, useTheme } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../../state/atoms';

const StyledOl = styled.ol`
  list-style: none;
  counter-reset: my-counter;

  > li {
    color: ${({ logoColor }) => logoColor};
    content: counter(my-counter) '. ';
  }

  ::selection {
    color: ${({ logoColor }) => logoColor};
  }
`;

const StyledUl = styled.ul`
  list-style: circle;

  ::selection {
    color: ${({ logoColor }) => logoColor};
  }
`;

const StyledLi = styled.li`
  counter-increment: my-counter;

  :before {
    color: ${({ logoColor }) => logoColor};
    font-weight: bold;
  }

  ::selection {
    color: ${({ logoColor }) => logoColor};
  }
`;

const StyledTypography = styled(Typography)`
  ::selection {
    color: ${({ logoColor }) => logoColor};
  }
`;

export const MarkdownOrderedList = ({ children }) => {
  const { palette } = useTheme();

  return <StyledOl logoColor={palette.info.main}>{children}</StyledOl>;
};

export const MarkdownUnorderedList = ({ children }) => {
  const { palette } = useTheme();

  return <StyledUl logoColor={palette.info.main}>{children}</StyledUl>;
};

export const MarkdownListItem = ({ children }) => {
  const theme = useRecoilValue(themeAtom);
  const { palette } = useTheme();

  return (
    <StyledLi logoColor={palette.info.main}>
      <section className={`${typography.typeface} ${typography[theme]}`}>
        {children}
      </section>
    </StyledLi>
  );
};
