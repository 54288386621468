import React, { useState, useEffect } from 'react';
import '../../styles/nav.scss';
import * as general from '../../styles/general.module.scss';
import { useRecoilState } from 'recoil';
import { themeAtom } from '../../state/atoms';
import { IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { _routes } from '../../routes';
import {
  WbSunny,
  ArrowUpward,
  ModeNight,
  FullscreenExit,
} from '@mui/icons-material';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { scrollToTop } from '../../util/domUtils';
import { DropMenu } from './DropMenu';
import { Fullscreen } from '@material-ui/icons';
import { config } from '../../state/atoms';

export const Nav = () => {
  //? Hook Stuff
  const history = useHistory();
  const { pathname } = useLocation();

  //? Application State
  const [conf, setConf] = useRecoilState(config);
  const [theme, setTheme] = useRecoilState(themeAtom);

  //? Component State
  const [filtered, setFiltered] = useState([]);
  const [hideOnScroll, setHideOnScroll] = useState(true);

  const filterRoutes = route =>
    route.name &&
    !['credit'].includes(route.name.toLowerCase()) &&
    pathname !== route.route;

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  useEffect(
    () => setFiltered(_routes.filter(f => filterRoutes(f))),
    [pathname],
  );

  return (
    <div
      className={`wrapper ${theme} ${!hideOnScroll && 'hidden'} sticky ${
        !hideOnScroll && 'shift'
      }`}>
      {/* nav options */}
      <section className={`${!hideOnScroll && 'hide'}`}>
        <DropMenu
          labels={filtered.map(({ route, name, nested }) => ({
            title: name,
            action: nested ? undefined : () => history.push(route),
            options: nested
              ? nested
                  .filter(n => n.name)
                  .map(({ path, name }) => ({
                    title: name,
                    action: () => history.push(`${route}${path}`),
                  }))
              : undefined,
          }))}
        />
      </section>

      <section className={'options'}>
        {/* theme changer */}
        <section className={`navItem`}>
          <IconButton
            onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
            {theme === 'light' ? (
              <ModeNight
                className={`${general.themedItem} ${general[theme]}`}
              />
            ) : (
              <WbSunny className={`${general.themedItem} ${general[theme]}`} />
            )}
          </IconButton>
        </section>

        {/* full screen */}
        {!conf.isMobile && (
          <section
            className={`navItem ${pathname.indexOf('/reading') < 0 && 'hide'}`}>
            <IconButton
              onClick={() =>
                setConf({ ...conf, fullScreenReader: !conf.fullScreenReader })
              }>
              {conf.fullScreenReader ? (
                <FullscreenExit
                  className={`${general.themedItem} ${general[theme]}`}
                />
              ) : (
                <Fullscreen
                  className={`${general.themedItem} ${general[theme]}`}
                />
              )}
            </IconButton>
          </section>
        )}

        {/* scroll to top */}
        <section className={`navItem ${hideOnScroll && 'hide'}`}>
          <IconButton onClick={scrollToTop}>
            <ArrowUpward
              className={`${general.themedItem} ${general[theme]}`}
            />
          </IconButton>
        </section>
      </section>
    </div>
  );
};
