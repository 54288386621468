/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from 'react';
import * as typography from '../../styles/typography.module.scss';
import { getIcon } from '../../util';
import PropTypes from 'prop-types';
import { useSpring, config, animated } from 'react-spring';
import styled from 'styled-components';
import { getColor } from '../../util/readUtil';
import { handleIconSize } from '../../util/readUtil';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';
import { shadow } from '../../styles/colors';

const StackIconStyle = styled(animated.div)`
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-flow: column nowrap;

  .icn {
    width: 4rem;
    height: 4rem;
    clip-path: polygon(
      0% 20%,
      90% 0%,
      100% 10%,
      100% 80%,
      10% 100%,
      0% 90%,
      0% 100%
    );
    padding: 0.5rem;
    border-radius: 7px;
    background-color: ${({ color }) => (color ? color : 'dimgrey')};
    background-image: url(${({ sv }) => sv}),
      url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23272727' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-size: ${({ size }) => `${handleIconSize(size)}rem`}, auto;
    background-repeat: no-repeat, repeat;
  }

  .label {
    line-height: 0.2 !important;
    z-index: 50;
    font-weight: 600;
  }
`;

export const StackIcon = ({ color, title, size, delay }) => {
  const theme = useRecoilValue(themeAtom);
  let timer = undefined;
  const [ready, setReady] = useState(false);

  const iconProps = useSpring({
    transform: `translateY(${ready ? 0 : -20}px)`,
    opacity: ready ? 1 : 0,
    config: config.gentle,
  });

  const textProps = useSpring({
    transform: `translateY(${ready ? 5 : -15}px) translateX(${
      ready ? 20 : -15
    }px)`,
    opacity: ready ? 1 : 0,
    config: config.gentle,
  });

  useEffect(() => {
    timer = setTimeout(() => setReady(true), delay);
    return () => clearTimeout(timer);
  }, []);

  return (
    <StackIconStyle
      style={iconProps}
      sv={getIcon(color)}
      color={getColor(color)}
      size={size}>
      <section className={'icn'} />

      <animated.section
        style={textProps}
        className={`${typography.typeface} ${typography[theme]}`}>
        <span className={typography.subtitleTwo}>
          <i>{title}</i>
        </span>
      </animated.section>
    </StackIconStyle>
  );
};

StackIcon.propTypes = {
  stack: PropTypes.object,
  color: PropTypes.string,
  delay: PropTypes.number,
};

StackIcon.defaultProps = {
  delay: 500, //500 milliseconds
};
