@use 'colors.module';

.timeWrapper {
  width: 66vw;
  padding: 0 7%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  bottom: -0.37rem;

  .shadow {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
