@use 'colors.module';

.aboutStyle {
  width: 100%;
  min-height: calc(100vh - 5rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;

  > .title {
    height: calc(100vh - 14rem);
    width: 80%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
  }

  > .mrkd {
    width: 80%;
    min-height: 50vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 2rem 0;
  }
}

.crossWrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;

  > .crossStyle {
    width: 2rem;

    &.dark {
      filter: drop-shadow(0 0 0.75rem colors.$textDark);
    }
  }
}

.highlight {
  &.light {
    color: colors.$lightHighlight;
  }

  &.dark {
    color: colors.$darkHighlight;
  }
}
