// @flow
/*eslint-disable no-throw-literal*/
/*eslint-disable react-hooks/exhaustive-deps*/
//https://www.aurigait.com/blog/realtime-database-in-firebase-v9/
import { useState, useEffect, useCallback } from 'react';
// $FlowIgnore[cannot-resolve-module]
import 'firebase/auth';
// $FlowIgnore[cannot-resolve-module]
import { getDatabase, ref, onValue } from 'firebase/database';
import {
  aboutAtom,
  usesAtom,
  readAtom,
  tipAtom,
  gistAtom,
  chipAtom,
  apiError,
  config,
} from '../state/atoms';
// $FlowIgnore[cannot-resolve-module]
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type {
  ApiStatus,
  AppAspects,
  Chip,
  GeneralRead,
  Gist,
  Read,
  Tip,
} from '../types';
import { firebaseConfig } from '../state/selectors';

/**
 * todo -> make the db read for visibility by flags -> env: ['dev', 'prod']
 * @param keys:array -> ['chips', 'about', 'reads', 'tips', 'gists']
 * @param immediate:boolean
 * @returns {{chips: [], reads: [], about: [], uses: [], gists: [], execute: (function(): Promise<void>), tips: [], addChipInfo: addChipInfo, status: ApiStatus}}
 */
export const useFirebaseDatabase = (
  keys: Array<AppAspects>,
  immediate: boolean = false,
): Object => {
  const database = getDatabase();

  //hook state
  const [init: boolean, setInit] = useState(false);
  const [status: ApiStatus, setStatus] = useState('idle');

  //application state
  const setApiError = useSetRecoilState(apiError);
  const conf = useRecoilValue(config);
  const firebase = useRecoilValue(firebaseConfig);
  const [chips: Array<Chip>, setChips] = useRecoilState(chipAtom);
  const [about: Array<GeneralRead>, setAbout] = useRecoilState(aboutAtom);
  const [uses: Array<GeneralRead>, setUses] = useRecoilState(usesAtom);
  const [reads: Array<Read>, setReads] = useRecoilState(readAtom);
  const [tips: Array<Tip>, setTips] = useRecoilState(tipAtom);
  const [gists: Array<Gist>, setGists] = useRecoilState(gistAtom);

  //* ---------------------------------- db calls
  const getChips = async () => {
    if (chips.length === 0)
      try {
        const chipRef = ref(database, firebase.tables.chip);

        onValue(chipRef, snapshot => {
          const data = snapshot.val();

          if (!data) throw 'api-error:empty_chips';
          else
            setChips(
              [4, 1, 2, 3, 5, 6].map(
                n => (data.filter(m => Number(m._id) === n)[0]: Chip),
              ),
            );
        });
      } catch (exception) {
        alert(exception);
      }
  };

  const getAbout = async () => {
    if (about.length === 0)
      try {
        const aboutRef = ref(database, firebase.tables.about);

        onValue(aboutRef, snapshot => {
          const data = snapshot.val();

          if (!data && !data.env.includes(conf.target))
            throw 'api-error:invalid_about';
          else setAbout((data: GeneralRead));
        });
      } catch (exception) {
        alert(exception);
      }
  };

  const getUses = async () => {
    if (uses.length === 0)
      try {
        const usesRef = ref(database, firebase.tables.uses);

        onValue(usesRef, snapshot => {
          const data = snapshot.val();

          if (!data && !data.env.includes(conf.target))
            throw 'api-error:invalid_uses';
          else setUses((data: GeneralRead));
        });
      } catch (exception) {
        alert(exception);
      }
  };

  const getReads = async () => {
    if (reads.length === 0)
      try {
        const readsRef = ref(database, firebase.tables.read);

        onValue(readsRef, snapshot => {
          const data = snapshot.val();

          if (!data) throw 'api-error:reads_about';
          else {
            data.forEach(d => {
              d.type = 'reads';
              if (chips.length > 0) d.chip = getChip(d._chip_id);
            });

            setReads(
              (data.filter(d => d.env.includes(conf.target)): Array<Read>),
            );
          }
        });
      } catch (exception) {
        alert(exception);
      }
  };

  const getTips = async () => {
    if (tips.length === 0)
      try {
        const tipsRef = ref(database, firebase.tables.tip);

        onValue(tipsRef, snapshot => {
          const data = snapshot.val();

          if (!data) throw 'api-error:empty_tips';
          else {
            data.forEach(d => {
              d.type = 'tips';

              if (chips.length > 0) d.chip = getChip(d._chip_id);
            });
            setTips(
              (data.filter(d => d.env.includes(conf.target)): Array<Tip>),
            );
          }
        });
      } catch (exception) {
        alert(exception);
      }
  };

  const getGists = async () => {
    if (gists.length === 0)
      try {
        const gistsRef = ref(database, firebase.tables.gist);

        onValue(gistsRef, snapshot => {
          const data = snapshot.val();

          if (!data) throw 'api-error:empty_gists';
          else {
            data.forEach(d => {
              d.type = 'gists';

              if (chips.length > 0) d.chip = getChip(d._chip_id);
            });
            setGists(
              (data.filter(d => d.env.includes(conf.target)): Array<Gist>),
            );
          }
        });
      } catch (exception) {
        alert(exception);
      }
  };

  const functions: Object = {
    chips: getChips,
    about: getAbout,
    reads: getReads,
    tips: getTips,
    gists: getGists,
    uses: getUses,
  };

  const execute = useCallback(async () => {
    setStatus('pending');

    /*
     * if we asked for the latest we then need to query for what the latest is generated from
     */
    if (keys.includes('latest')) {
      keys = [
        ...new Set([
          ...keys.filter(n => n !== 'latest'),
          'chips',
          'gists',
          'reads',
          'tips',
        ]),
      ];
    }

    await Promise.all(
      keys.map(async thing => {
        await functions[thing]();
      }),
    ).catch(error => {
      setApiError(error);
      setStatus('error');
    });

    setStatus('success');
    setInit(true);
  }, [keys]);

  //* -------------------------------- formatters
  const addChipInfo = data => {
    setStatus('busy');

    data.forEach(el => {
      const _chip = chips.filter(c => Number(c._id) === Number(el._chip_id))[0];

      if (!!_chip) {
        const { color, title } = _chip;
        el.chip = { color, title };
      }
      delete el._id;
    });

    setStatus('idle');
  };

  const getChip = chip_id =>
    chips.filter(c => Number(c._id) === Number(chip_id))[0];

  useEffect(() => {
    if (immediate && !init) execute().catch(() => {});
  }, [reads, gists, tips, chips, about, uses]);

  return {
    execute,
    addChipInfo,
    status,
    chips,
    reads,
    tips,
    gists,
    uses,
    about,
  };
};
