import React from 'react';
import * as typography from '../../../styles/typography.module.scss';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../../state/atoms';
import { normalize } from '../../../util/domUtils';

export const MarkdownH1 = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
      <h1>{children}</h1>
    </section>
  );
};

export const MarkdownH2 = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      id={normalize(children)}
      className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
      <h2>{children}</h2>
    </section>
  );
};

export const MarkdownH3 = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      id={normalize(children)}
      className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
      <h3>{children}</h3>
    </section>
  );
};

export const MarkdownH4 = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      id={normalize(children)}
      className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
      <h4>{children}</h4>
    </section>
  );
};

export const MarkdownH5 = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
      <h5>{children}</h5>
    </section>
  );
};

export const MarkdownH6 = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      className={`${typography.typeface} ${typography[theme]} ${typography.center}`}>
      <h6>{children}</h6>
    </section>
  );
};
