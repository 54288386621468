@use 'colors.module';

.appStyle {
  width: 100%;
  margin: 0;

  &.light {
    background-color: colors.$bgLight;
  }

  &.dark {
    background-color: colors.$bgDark;
  }
}
