import React from 'react';
import * as style from '../../../styles/markdown.module.scss';
import * as typeface from '../../../styles/typography.module.scss';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../../state/atoms';

export const MarkdownTable = ({ children, ...props }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section className={`${style.mrdkTableWrapper} ${typeface.typeface}`}>
      <section
        className={`${typeface.tableFace} ${style.mrkdTable} ${style[theme]}`}>
        {children}
      </section>
    </section>
  );
};
