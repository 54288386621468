import styled from 'styled-components';
import { GridList, GridListTile } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
`;

const StyledGridList = styled(GridList)`
  width: 500px;
  height: 450px;
`;

const StyledGridListTile = styled(GridListTile)`
  height: auto;
`;

export { Wrapper, StyledGridList, StyledGridListTile };
