@use 'colors.module';

.themedItem {
  &.light {
    color: colors.$textLight !important;
  }

  &.disabled {
    color: rgba(colors.$textLight, 0.56) !important;
  }

  &.dark {
    color: colors.$textDark !important;
  }
}
