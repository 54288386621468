@use 'colors.module';

.wrapper {
  width: 100%;
  padding: 2em 0 0 0;
}

.wrapper > .pages {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;

  &.cols {
    flex-flow: column wrap;
  }

  > .item {
    margin: 1%;
    padding: 1%;

    & :hover {
      cursor: pointer;
    }
  }
}
