import { atom } from 'recoil';
import { twitter } from '../assets';
import { credits } from '../config/credits';

const config = atom({
  key: 'AppConfig',
  default: {
    target: process.env.TARGET,
    isMobile: typeof window.orientation !== 'undefined',
    social: [
      {
        title: process.env.REACT_APP_TWITTER_HANDLE,
        url: process.env.REACT_APP_TWITTER_URL,
        image: twitter,
      },
    ],
    page_creds: credits,
    firebase: {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    },
    locations: {
      base: process.env.DATA_BASE,
      chips: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_CHIPS,
      },
      gists: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_GISTS,
      },
      hits: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_HITS,
      },
      about: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_ABOUT,
        storage: process.env.REACT_APP_STORAGE_ABOUT,
      },
      uses: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_USES,
        storage: process.env.REACT_APP_STORAGE_USES,
      },
      reads: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_READS,
        storage: process.env.REACT_APP_READS,
      },
      tips: {
        id: process.env.REACT_APP_ID_COL,
        tbl: process.env.REACT_APP_DB_TIPS,
        storage: process.env.REACT_APP_TIPS,
      },
    },
    wpm: process.env.REACT_APP_AVG_WPM,
    uses: process.env.REACT_APP_USES,
    uses_img: process.env.REACT_APP_USES_IMG,
    about: process.env.REACT_APP_ABOUT,
    about_img: process.env.REACT_APP_ABOUT_IMG,
    twitter_handle: process.env.REACT_APP_TWITTER_HANDLE,
    twitter_url: process.env.REACT_APP_TWITTER_URL,
    themes_alt_light: process.env.REACT_APP_THEMES_ALT_LIGHT,
    themes_light: process.env.REACT_APP_THEMES_LIGHT,
    themes_alt_dark: process.env.REACT_APP_THEMES_ALT_DARK,
    themes_dark: process.env.REACT_APP_THEMES_DARK,
    //full screen reader by default if in mobile, otherwise use the flag that's set
    fullScreenReader: typeof window.orientation !== 'undefined' || false,
  },
});

const chipAtom = atom({
  key: 'chips',
  default: [],
});

const aboutAtom = atom({
  key: 'about',
  default: [],
});

const usesAtom = atom({
  key: 'uses',
  default: [],
});

const readAtom = atom({
  key: 'reads',
  default: [],
});

const tipAtom = atom({
  key: 'tips',
  default: [],
});

const gistAtom = atom({
  key: 'gists',
  default: [],
});

const selectedBlogType = atom({
  key: 'SelectedBlogType',
  default: '',
});

const selectedSlug = atom({
  key: 'SelectedSlug',
  default: '',
});

const themeAtom = atom({
  key: 'appTheme',
  default: 'light',
});

const apiError = atom({
  key: 'apiError',
  default: undefined,
});

const headings = atom({
  key: 'blogHeadings',
  default: [],
});

export {
  config,
  chipAtom,
  aboutAtom,
  usesAtom,
  readAtom,
  tipAtom,
  gistAtom,
  selectedBlogType,
  selectedSlug,
  themeAtom,
  apiError,
  headings,
};
