.directorStyle {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.directorStyle > .bookShelf {
  width: 99%;
  display: grid;
  grid-gap: 0.5rem;
  justify-content: space-between;
  align-content: space-between;
  justify-items: center;
}
