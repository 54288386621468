.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.flexRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
}

.headings {
  position: sticky;
  top: 0;
  right: 0;
  padding: 3rem 0;
  width: 12rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  .caption {
    min-height: 3em;
    margin: 0.2em 0;
  }
}
