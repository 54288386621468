.pagingStyle {
  width: 12rem;
  padding: 3rem 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.pagingStyle > .pagingDisplay {
  width: 100%;
}

.pagingStyle > .pagingDisplay > .pageItem {
  width: 100%;
  margin: 1% 0;
  display: inline-flex;
  justify-content: center;
}

.pageInfo {
  width: 100%;
  margin: 1% 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;

  > .pageText {
    width: 10rem;
  }
}
