/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as typography from '../../../styles/typography.module.scss';
import * as style from '../../../styles/markdown.module.scss';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia, ghcolors } from 'react-syntax-highlighter/dist/styles/prism';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../../state/atoms';

/*
  ? prism themes
  * coy
  * dark
  * funky
  * okaidia
  * solarizedlight
  * tomorrow
  * twilight
  * prism
  * atomDark
  * base16AteliersulphurpoolLight
  * cb
  * darcula
  * duotoneDark
  * duotoneEarth
  * duotoneForest
  * duotoneLight
  * duotoneSea
  * duotoneSpace
  * ghcolors
  * hopscotch
  * pojoaque
  * vs
  * xonokai
 */
export const MarkdownCode = ({ children, ...props }) => {
  const theme = useRecoilValue(themeAtom);
  const [lang, setLang] = useState('');
  const [multiLine, setMultiLine] = useState(false);

  useEffect(() => {
    const { className } = props;

    if (className) setLang(className.replace('lang-', ''));
    if (children.includes('\n')) setMultiLine(true);
  }, []);

  return (
    <>
      {multiLine ? (
        <div className={style.codeWrapper}>
          <SyntaxHighlighter
            language={lang}
            style={theme === 'light' ? ghcolors : okaidia}
            codeTagProps={{
              fontFamily: "'Victor Mono', monospace",
              width: 'auto',
              wordWrap: 'normal',
            }}
            {...props}>
            {children}
          </SyntaxHighlighter>
        </div>
      ) : (
        <section
          className={`${typography.typeface} ${typography.line} ${typography[theme]} ${typography.markdown}`}>
          <code>{children}</code>
        </section>
      )}
    </>
  );
};
