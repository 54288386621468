.usesStyle {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  > .mrkd {
    width: 80%;
    min-height: 50vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 2rem 0;
  }
}
