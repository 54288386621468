import { java, js, react, spring, idea, git } from '../assets';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

Moment.globalMoment = moment;
Moment.globalFormat = 'MM/DD/YYYY';
Moment.globalLocale = 'en';
Moment.globalLocal = true;

const provideReadTime = text => {
  const totalWords = text.split(/s/g).length;
  const minutes = totalWords / Number(process.env.REACT_APP_AVG_WPM);
  const readTime = Math.ceil(minutes);

  return {
    words: totalWords,
    mins: minutes,
    mins_rounded: readTime,
    display_text: `${readTime} minute read`,
  };
};

const handleSizing = size => {
  switch (size) {
    case 'small':
      return 8.4;
    case 'medium':
      return 25;
    default:
      //large
      return 35;
  }
};

const handleIconSize = size => {
  switch (size) {
    case 'small':
      return 1.8;
    case 'medium':
      return 4;
    default:
      //large
      return 6;
  }
};

const handleIconPosition = size => {
  switch (size) {
    case 'small':
      return 2.2;
    case 'medium':
      return 7.5;
    default:
      //large
      return 10.3;
  }
};

const getIcon = color => {
  switch (color) {
    case 'blog-orange':
      return idea;
    case 'js-yellow':
      return js;
    case 'java-red':
      return java;
    case 'spring-green':
      return spring;
    case 'my-blue':
      return react;
    case 'github-black':
      return git;
    default:
      return idea;
  }
};

const getColor = color => {
  if (color.includes('blog-orange')) return '#ff6600';

  if (color.includes('js-yellow')) return '#fdd835';

  if (color.includes('java-red')) return '#d32f2f';

  if (color.includes('spring-green')) return '#388e3c';

  if (color.includes('my-blue')) return '#1e88e5';

  if (color.includes('github-black')) return '#171515';

  return '#5e56ff';
};

const USnow = moment().format('llll');

const slugify = slug => {
  const strings = slug.split('-');

  return strings
    .map(str => str[0].toUpperCase() + str.substring(1) + ' ')
    .toString()
    .replaceAll(',', '')
    .trim();
};

const sortByDate = things =>
  things.sort((a, b) => {
    if (moment(a.date).isBefore(b.date)) return 1;

    if (moment(a.date).isAfter(b.date)) return -1;

    return 0;
  });

export {
  provideReadTime,
  handleSizing,
  handleIconPosition,
  handleIconSize,
  getIcon,
  getColor,
  USnow,
  slugify,
  sortByDate,
};
