import { selector } from 'recoil';
import {
  config,
  gistAtom,
  readAtom,
  selectedBlogType,
  selectedSlug,
  tipAtom,
} from './atoms';
import moment from 'moment/moment';

const selectedBlogInfoState = selector({
  key: 'selectedBlogInfo',
  get: ({ get }) => {
    const _type = get(selectedBlogType);
    const _slug = get(selectedSlug);
    const reads = get(readAtom);
    const tips = get(tipAtom);
    const gists = get(gistAtom);

    const list = [...reads, ...tips, ...gists];

    if (!!_type && !!_slug && list.length > 0) {
      const selected = list.filter(
        el => el.type === _type && el.slug === _slug,
      )[0];
      return selected;
    }
  },
});

const latestRead = selector({
  key: 'latestReads',
  get: ({ get }) => {
    const _reads = get(readAtom);
    const _tips = get(tipAtom);
    const _gists = get(gistAtom);

    let latest = {};

    if (_reads.length > 0 && _tips.length > 0 && _gists.length > 0)
      latest = [..._reads, ..._tips, ..._gists]
        .sort((a, b) =>
          moment(a.date).isAfter(b.date)
            ? 1
            : moment(a.date).isBefore(b.date)
            ? -1
            : 0,
        )
        .pop();

    return latest;
  },
});

const firebaseConfig = selector({
  key: 'fbase',
  get: ({ get }) => {
    const {
      locations: { base, chips, gists, hits, about, reads, tips, uses },
    } = get(config);

    return {
      tables: {
        chip: `${base}/${chips.tbl}`,
        gist: `${base}/${gists.tbl}`,
        hit: `${base}/${hits.tbl}`,
        about: `${base}/${about.tbl}`,
        uses: `${base}/${uses.tbl}`,
        read: `${base}/${reads.tbl}`,
        tip: `${base}/${tips.tbl}`,
      },
      storage: {
        about: `${base}${about.storage}`,
        uses: `${base}${uses.storage}`,
        reads: `${base}${reads.storage}`,
        tips: `${base}${tips.storage}`,
      },
    };
  },
});

export { selectedBlogInfoState, latestRead, firebaseConfig };
