import React, { useEffect } from 'react';
import * as style from '../../styles/markdownReader.module.scss';
import * as mdStyle from '../../styles/markdown.module.scss';
import * as tf from '../../styles/typography.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import { useFirebaseDatabase, useFirebaseCloudStorage } from '../../hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { latestRead, selectedBlogInfoState } from '../../state/selectors';
import { MarkdownDisplay } from '../markdown/MarkdownDisplay';
import { GistView } from './GistView';
import {
  config,
  headings,
  selectedBlogType,
  selectedSlug,
  themeAtom,
} from '../../state/atoms';
import { Loading } from './Loading';
import { Paging } from '../nav/Paging';
import { ReadTime } from './index';
import { AnimateItem } from '../animation/AnimateItem';
import { Details } from './Details';

/**
 * This component is responsible for showing any blog entry
 * @returns {JSX.Element}
 * @constructor
 */
export const MarkdownReader = () => {
  //url stuff
  const { pathname } = useLocation();
  const { type, slug, title } = useParams();

  //application state
  const [_type, setType] = useRecoilState(selectedBlogType);
  const [_slug, setSlug] = useRecoilState(selectedSlug);
  const latest = useRecoilValue(latestRead);
  const currentRead = useRecoilValue(selectedBlogInfoState);
  const jumps = useRecoilValue(headings);
  const theme = useRecoilValue(themeAtom);
  const { fullScreenReader } = useRecoilValue(config);

  //hooks
  const { reads, gists, tips } = useFirebaseDatabase(
    pathname === '/reading/latest' ? ['latest'] : ['reads', 'tips', 'gists'],
    true,
  );

  const { markdown, readFile } = useFirebaseCloudStorage(type, slug, false);

  useEffect(() => {
    if (pathname === '/reading/latest' && latest) {
      const { slug: s, type: t } = latest;

      if (s && t) {
        setSlug(s);
        setType(t);

        if (type !== 'gists') readFile(t, s);
      }
    } else {
      if (type && slug) {
        setSlug(slug);
        setType(type);

        if (type !== 'gists') readFile(type, slug);
      }
    }
  }, [pathname, currentRead, latest, slug, type]);

  return (
    <>
      {!currentRead && <Loading />}

      {currentRead && (
        <div className={style.wrapper}>
          {/* banner for current read */}
          {currentRead.banner && (
            <section className={mdStyle.banner}>
              <img src={currentRead.banner} alt={''} />

              <section className={mdStyle.time}>
                <ReadTime markdown={markdown} />
              </section>
            </section>
          )}

          {/* current read */}
          <section className={style.flexRow}>
            {/* All other reads */}
            <AnimateItem
              trigger={!fullScreenReader}
              animations={['fade', 'in-left', 'out-left']}>
              <section>
                <Paging viewCount={10} data={[...reads, ...gists, ...tips]} />
              </section>
            </AnimateItem>

            {/* reads and tips */}
            {['reads', 'tips'].includes(currentRead.type) && (
              <MarkdownDisplay markdown={markdown} />
            )}

            {/* gists */}
            {currentRead.type === 'gists' && (
              <GistView
                id={currentRead._gist_id}
                title={currentRead.title}
                date={currentRead.date}
              />
            )}

            {/* headings */}
            <AnimateItem
              trigger={!fullScreenReader}
              animations={['fade', 'in-right', 'out-right']}>
              <section className={style.headings}>
                <section
                  className={`${tf.typeface} ${tf[theme]} ${tf.right} ${tf.edgeRight} ${tf.underline}`}>
                  <span className={tf.subtitleOne}>Sections</span>
                </section>

                {jumps.map(({ id, text }, index) => (
                  <section className={style.caption}>
                    <Details
                      isCard
                      title={text}
                      orientation={'right'}
                      toSection={{ id }}
                      last={jumps.length === index}
                    />
                  </section>
                ))}
              </section>
            </AnimateItem>
          </section>
        </div>
      )}
    </>
  );
};
