import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 3.5em;
  padding: 0 0.5rem;
`;

const StyledImg = styled.img`
  width: 100%;
`;

export const SocialCard = ({ img_src, url, title }) => {
  return (
    <Wrapper>
      <a href={url}>
        <StyledImg src={img_src} />
      </a>
    </Wrapper>
  );
};
