//@flow
import React from 'react';
import { XyzTransitionGroup } from '@animxyz/react';

interface Props {
  trigger?: boolean;
  animations: Array<string>;
  children: Node;
  +className?: string;
}

export const AnimateGroup = ({
  trigger = true,
  animations = [],
  children,
  className = undefined,
}: Props) => (
  <XyzTransitionGroup
    className={className}
    xyz={[...animations, 'stagger'].join(' ')}>
    {trigger && children}
  </XyzTransitionGroup>
);
