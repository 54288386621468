import React from 'react';
import {
  GridListTile,
  GridListTileBar,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import {
  Wrapper,
  StyledGridList,
  StyledGridListTile,
} from '../styles/CreditStyle';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { config } from '../state/atoms';

const TitleWrapper = styled.section`
  padding-top: 4%;
  width: 100%;
`;

export const Credits = () => {
  const { page_creds } = useRecoilValue(config);

  return (
    <Wrapper>
      <StyledGridList cellHeight={180}>
        <StyledGridListTile key='Subheader' cols={2}>
          <ListSubheader component='div'>
            <TitleWrapper>
              <Typography
                variant={'h4'}
                gutterBottom
                align={'center'}
                color={'textPrimary'}>
                Credits
              </Typography>
            </TitleWrapper>
          </ListSubheader>
        </StyledGridListTile>

        {page_creds.map(e => (
          <GridListTile key={e.name}>
            <img src={e.image} alt={e.title} />
            <GridListTileBar title={e.title} subtitle={e.credit} />
          </GridListTile>
        ))}
      </StyledGridList>
    </Wrapper>
  );
};
