//@flow
import React from 'react';
import '../../styles/filteredBackground.scss';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';

export const FilteredBackground = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <div className={'fbShadow'}>
      <div className={`fbWrapper ${theme}`}>
        <section className={'fbWrapperInside'}>{children}</section>
      </div>
    </div>
  );
};
