import React, { useState, useEffect } from 'react';
import * as style from '../../styles/paging.module.scss';
import * as general from '../../styles/general.module.scss';
import * as typography from '../../styles/typography.module.scss';
import PropTypes from 'prop-types';
import { Details } from '../read/Details';
import { slugify, sortByDate } from '../../util/readUtil';
import { IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';
import { scrollToTop } from '../../util/domUtils';

export const Paging = ({ data, viewCount }) => {
  // application state
  const theme = useRecoilValue(themeAtom);

  // component state
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(viewCount);
  const [page, setPage] = useState([]);

  const pageForward = () => {
    if (start < data.length - viewCount) {
      setStart(start + viewCount);
      setEnd(end + viewCount);
      scrollToTop();
    }
  };

  const pageBackward = () => {
    if (start !== 0) {
      setStart(start - viewCount);
      setEnd(end - viewCount);
      scrollToTop();
    }
  };

  const Info = () => (
    <section className={style.pageInfo}>
      <IconButton size={'small'} disabled={start === 0} onClick={pageBackward}>
        <ArrowBack
          className={`${general.themedItem} ${
            start === 0 ? general.disabled : general[theme]
          }`}
        />
      </IconButton>

      <IconButton
        size={'small'}
        disabled={start / viewCount + 1 === data.length / viewCount}
        onClick={pageForward}>
        <ArrowForward
          className={`${general.themedItem} ${
            end === data.length ? general.disabled : general[theme]
          }`}
        />
      </IconButton>
    </section>
  );

  useEffect(() => {
    setPage(sortByDate(data.slice(start, end)));
  }, [data, start, end]);

  return (
    <div className={style.pagingStyle}>
      <section className={style.pagingDisplay}>
        <section
          className={`${typography.typeface} ${typography[theme]} ${typography.underline}`}>
          <span className={typography.subtitleOne}>All the reads</span>
        </section>

        {page.map((el, index) => {
          const { slug, date, type, chip } = el;

          return (
            <section className={`${style.pageItem}`}>
              <Details
                isCard
                title={slugify(slug)}
                date={date}
                orientation={'left'}
                toUrl={{ type, slug }}
                last={page.length === index + 1}
              />
            </section>
          );
        })}
      </section>

      <Info />
    </div>
  );
};

Paging.propTypes = {
  data: PropTypes.array,
  viewCount: PropTypes.number,
  full: PropTypes.bool,
};

Paging.defaultProps = {
  data: [],
  viewCount: 5,
};
