/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  useTheme,
  Popper,
} from '@material-ui/core';
import FormatColorFillTwoToneIcon from '@material-ui/icons/FormatColorFillTwoTone';
import styled from 'styled-components';

const StyledPopper = styled(Popper)`
  z-index: 5;

  > .MuiPaper-root {
    background-color: ${({ bkgrnd }) => bkgrnd} !important;
  }
`;

/**
 * @Depreciated
 * This hook is meant to be used at the App level of your ReactJs application
 * where you would normally use the Material-Ui Theme Provider. If you're not
 * familiar with customizing this library then I suggest taking a few minutes
 * to read the docs @ https://material-ui.com/customization/theming/.
 *
 * This hook returns the following:
 * - LodSelector: Component that will handle the actual theme switching
 * - LodProvider: Material-Ui theme provider with theme already passed in
 * - current: Name of the currently selected theme
 *
 *
 * @param _colors
 * @param _selections
 * @param _conf
 * @returns {{current: string | null, LodProvider: (function({children: *}): JSX.Element), LodSelector: (function(): JSX.Element)}}
 */
export const useLightOrDark = (_colors, _selections, _conf) => {
  let storage = window.localStorage;

  //! --------------------------------------------------> Lazy Loading Defaults
  const [colors] = useState(() =>
    !!_colors ? _colors : useLightOrDark.defaults.colors,
  );

  const [selections] = useState(() =>
    !!_selections ? _selections : useLightOrDark.defaults.selections,
  );

  const [conf] = useState(() =>
    !!_conf ? _conf : useLightOrDark.defaults.conf,
  );

  const [current, setCurrent] = useState(() =>
    storage.getItem(conf.storageKey)
      ? storage.getItem(conf.storageKey)
      : selections[conf.defaultTheme].value,
  );
  //! -< end Lazy Loading

  /**
   *
   * @type {Theme}
   */
  const theme = createMuiTheme({
    logo: colors[current].logo,
    style: colors[current].name,
    syntax: colors[current].syntax,
    shadow: colors[current].shadow,
    palette: {
      text: {
        primary: colors[current].primary.text,
        secondary: colors[current].secondary.text,
      },
      primary: {
        light: colors[current].primary.light,
        dark: colors[current].primary.dark,
        main: colors[current].primary.main,
        text: colors[current].primary.text,
      },
      secondary: {
        light: colors[current].secondary.light,
        dark: colors[current].secondary.dark,
        main: colors[current].secondary.main,
        text: colors[current].secondary.text,
      },
      info: {
        light: colors[current].info.light,
        dark: colors[current].info.dark,
        main: colors[current].info.main,
      },
      warning: {
        light: colors[current].warning.light,
        dark: colors[current].warning.dark,
        main: colors[current].warning.main,
      },
    },
  });

  /**
   * Function that does the actual theme switching
   * @param changeTo
   */
  const switchTheme = changeTo => {
    storage.setItem(conf.storageKey, changeTo);
    setCurrent(changeTo);
  };

  /**
   * MUi Theme Provider
   *
   * @param children
   * @returns {JSX.Element}
   * @constructor
   */
  const LodProvider = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  );

  /**
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const LodSelector = () => {
    const theme = useTheme();

    const [anchorRef] = useState(createRef());

    const [open, setOpen] = React.useState(false);

    const [selectedIndex, setSelectedIndex] = React.useState();

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      switchTheme(selections[index].value);
      setOpen(!open);
    };

    return (
      <>
        <Button
          color={'primary'}
          size={'small'}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          ref={anchorRef}
          onClick={() => setOpen(!open)}>
          <FormatColorFillTwoToneIcon htmlColor={theme.palette.warning.light} />
        </Button>
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          bkgrnd={theme.palette.primary.main}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper color={'primary'}>
                <ClickAwayListener onClickAway={() => setOpen(!open)}>
                  <MenuList id='split-button-menu'>
                    {selections.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        disabled={option.disable}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </StyledPopper>
      </>
    );
  };

  /**
   * If the user has selected a previous theme on the next page load then we
   * keep that theme from the last user selection. Local storage gets updated
   * each time the user selects a new theme so we only need to load the last
   * one that was selected on the first page load.
   */
  useEffect(() => {
    if (storage.getItem(conf.storageKey))
      setCurrent(storage.getItem(conf.storageKey));
  }, []);

  //! -< Return the important stuff
  return {
    current,
    LodProvider,
    LodSelector,
  };
};

useLightOrDark.defaults = {
  colors: {
    light: {
      name: 'light',
      primary: {
        dark: '#222222',
        light: '#93A1A1',
        main: '#93A1A1',
        text: '#272727',
      },
      secondary: {
        dark: '#222222',
        light: '#93A1A1',
        main: '#F8F8F8',
        text: '#272727',
      },
      info: {
        dark: '#803400',
        light: '#BA4B00',
        main: '#ff6600',
      },
      warning: {
        dark: '#053354',
        light: '#084876',
        main: '#0A64A4',
      },
    },
    dark: {
      name: 'dark',
      primary: {
        dark: '#93A1A1',
        light: '#F8F8F8',
        main: '#222222',
        text: '#B6C1DD',
      },
      secondary: {
        dark: '#222222',
        light: '#93A1A1',
        main: '#384747',
        text: '#272727',
      },
      info: {
        dark: '#7F921E',
        light: '#99B600',
        main: '#AABF3E',
      },
      warning: {
        dark: '#FFC300',
        light: '#FFC404',
        main: '#FFD13A',
      },
    },
  },
  selections: [
    { name: 'Light', value: 'light' },
    { name: 'Dark', value: 'dark' },
  ],
  conf: {
    defaultTheme: 0,
    storageKey: 'lightOrDarkTheme',
  },
};
