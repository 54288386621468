const isBrowser = typeof window !== `undefined`;

const getScrollPosition = ({ element, useWindow }) => {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

const normalize = ([text]) => {
  return typeof text === 'string'
    ? text
        .trim()
        .toLowerCase()
        .replace(/#/g, '')
        .replace(/ /g, '_')
        .replace(/[?|!]/g, '')
    : undefined;
};

const moveTo = id => {
  const element = document.getElementById(id);

  if (element) element.scrollIntoView({ behavior: 'smooth' });
};

export { getScrollPosition, scrollToTop, normalize, moveTo };
