//@flow
import React from 'react';
import { XyzTransition } from '@animxyz/react';

interface Props {
  trigger: boolean;
  animations: Array<string>;
  children: Node;
  className: string;
}

export const AnimateItem = ({
  trigger = true,
  animations = [],
  children,
  className = undefined,
}): Props => (
  <XyzTransition className={className} xyz={animations.join(' ')}>
    {trigger && children}
  </XyzTransition>
);
