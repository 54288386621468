import React, { useEffect } from 'react';
import * as style from '../../styles/readtime.module.scss';
import * as mdStyle from '../../styles/markdown.module.scss';
import * as typography from '../../styles/typography.module.scss';
import { config, animated, useSpring } from 'react-spring';
import { provideReadTime } from '../../util';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../state/atoms';

const ReadTime = ({ markdown }) => {
  const theme = useRecoilValue(themeAtom);
  const { mins } = provideReadTime(markdown);

  const props = useSpring({
    config: { ...config.slow },
    number: mins ? mins : 0,
    from: { number: 0 },
  });

  /*
   * display_text: "4 minute read"
   * mins: 3.31
   * mins_rounded: 4
   * words: 662
   */

  useEffect(() => {});

  return (
    <div className={style.timeWrapper}>
      <hr className={`${mdStyle.rule} ${mdStyle[theme]}`} />

      <div className={style.shadow}>
        <>
          <section
            className={`${typography.typeface} ${typography.shift} ${typography.center} ${typography.italics}`}>
            <h5>
              <animated.span>
                {props.number.interpolate(x => x.toFixed(1))}
              </animated.span>
              &nbsp;minute read
            </h5>
          </section>
        </>
      </div>
    </div>
  );
};

export default ReadTime;
