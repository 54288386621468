// @flow
//https://travis.media/how-to-use-firebase-with-react/
//https://dev.to/mjoycemilburn/getting-serious-with-firebase-v9-part-4-cloud-storage-uploading-files-3p7c
import { useEffect, useCallback, useState } from 'react';
// $FlowIgnore[cannot-resolve-module]
import { ref, getStorage, getDownloadURL } from 'firebase/storage';
// $FlowIgnore[cannot-resolve-module]
import axios from 'axios';
// $FlowIgnore[cannot-resolve-module]
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiError } from '../state/atoms';
import type { ApiStatus, AppAspects } from '../types';
import { firebaseConfig } from '../state/selectors';

export const useFirebaseCloudStorage = (
  type: AppAspects,
  slug: string,
  immediate: boolean = true,
): Object => {
  //hook state
  const [markdown, setMarkdown] = useState('');
  const [status: ApiStatus, setStatus] = useState('idle');

  //application state
  const setError = useSetRecoilState(apiError);
  const firebase = useRecoilValue(firebaseConfig);

  //firebase
  const storage = getStorage();

  const getMarkdown = useCallback(
    async (t, s) => {
      setStatus('loading');

      if (t) type = t;

      if (s) slug = s;

      if (type && slug) {
        const url = await getDownloadURL(
          ref(storage, `${firebase.storage[type]}/${slug}.md`),
        ).catch(error => {
          setStatus('error');
          setError(error);
        });

        const response = await axios.get(url).catch(error => {
          setStatus('error');
          setError(error);
        });

        if (response) {
          const { status, statusText, data } = response;

          if (status === 200) {
            setMarkdown(data);
            setStatus('idle');
          } else {
            setStatus('error');
            setError(statusText);
          }
        } else {
          setStatus('error');
          setError('found nothing');
        }
      }
    },
    [type, slug],
  );

  useEffect(() => {
    if (immediate) getMarkdown();
  }, []);

  return {
    markdown,
    readFile: getMarkdown,
    status,
  };
};
