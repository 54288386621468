@use 'colors.module';
$steps: 44;
$typewriterWidth: 15.4em;
$cursorColor: rgba(colors.$textLight, 0.95);

.line1 {
  position: relative;
  top: 50%;
  width: 24em;
  margin: 0 auto;
  border-right: 2px solid $cursorColor;
  mix-blend-mode: difference;
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

/* Animation */
.animTypewriter {
  animation: typewriter 4s steps($steps) 1s 1 normal both,
    blinkTextCursor 500ms steps($steps) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: $typewriterWidth;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: $cursorColor;
  }
  to {
    border-right-color: transparent;
  }
}
