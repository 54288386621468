import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Redirect } from 'react-router-dom';
import { apiError } from '../../state/atoms';

/**
 * This component is an error redirect for when there is an error
 * in the state
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ErrorRedirect = () => {
  const [error, setError] = useRecoilState(apiError);

  useEffect(() => setError(undefined), [error]);

  return (
    <>
      {error && (
        <Redirect
          to={{
            pathname: '/error',
            state: { error: error },
          }}
        />
      )}
    </>
  );
};
