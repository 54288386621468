import React from 'react';
import * as style from '../../styles/footer.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { config, themeAtom } from '../../state/atoms';
import { _routes } from '../../routes';
import * as typography from '../../styles/typography.module.scss';
import { Logo } from '../theme/Logo';
import { SocialCard } from '../communication/SocialCard';

export const Footer = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useRecoilValue(themeAtom);
  const { social } = useRecoilValue(config);

  return (
    <div className={`${style.wrapper} ${style[theme]}`}>
      <section className={style.pages}>
        {_routes
          .filter(
            f =>
              f.name &&
              pathname !== f.route &&
              !['/contactMe'].includes(f.route), //for now leaving out the contact me page
          )
          .map(({ route, name }, index) => (
            <section
              key={index}
              className={style.item}
              onClick={() => history.push(route)}>
              <h3
                className={`${typography.typeface} ${typography.upper} ${typography[theme]}`}>
                /{name}
              </h3>
            </section>
          ))}
      </section>

      <section className={`${style.pages} ${style.cols}`}>
        <section className={style.item}>
          <Logo />
        </section>

        {social.map(e => (
          <section className={style.item}>
            <SocialCard img_src={e.image} url={e.url} title={e.title} />
          </section>
        ))}
      </section>
    </div>
  );
};
