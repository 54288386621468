import React from 'react';
import * as style from '../../../styles/markdown.module.scss';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../../state/atoms';

export const MarkdownHr = () => {
  const theme = useRecoilValue(themeAtom);

  return <hr className={`${style.rule} ${style[theme]}`} />;
};
