//@flow
import React, { useState, useEffect } from 'react';
import * as style from '../../styles/markdown.module.scss';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import {
  MarkdownCode,
  MarkdownH1,
  MarkdownH2,
  MarkdownH3,
  MarkdownH4,
  MarkdownH5,
  MarkdownH6,
  MarkdownHr,
  MarkdownImage,
  MarkdownLink,
  MarkdownListItem,
  MarkdownOrderedList,
  MarkdownP,
  MarkdownTable,
  MarkdownUnorderedList,
} from './elements';
import { normalize, scrollToTop } from '../../util/domUtils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { config, headings } from '../../state/atoms';

interface Props {
  markdown: string;
}

export const MarkdownDisplay = ({ markdown }: Props) => {
  //application state
  const set = useSetRecoilState(headings);
  const { fullScreenReader } = useRecoilValue(config);

  //component state
  const [text, setText] = useState('');

  useEffect(() => {
    scrollToTop();
    set([]);

    //split on new line and remove all the windows new line chars
    let lines = markdown.split('\n').map(line => line.replace('\r', ''));
    const first = lines.indexOf('---');

    //if the first instance of --- isn't in the first few lines then forget about it
    if (first < 2) {
      const second = lines.indexOf('---', first + 1);
      lines = lines.splice(second + 1, lines.length);
    }

    //set headings
    set(
      lines
        .filter(n => new RegExp(/^##/).test(n))
        .map(text => ({
          el: (text.match(/#/g) || []).length === 2 ? 'h2' : 'h3',
          id: normalize([text]),
          text: text.replace(/#/g, ''),
        })),
    );

    //set mark down text
    setText(lines.join('\n'));
  }, [markdown]);

  return (
    <div className={`${style.reading} ${fullScreenReader && style.full}`}>
      <Markdown
        options={{
          overrides: {
            code: MarkdownCode,
            p: MarkdownP,
            h1: MarkdownH1,
            h2: MarkdownH2,
            h3: MarkdownH3,
            h4: MarkdownH4,
            h5: MarkdownH5,
            h6: MarkdownH6,
            a: MarkdownLink,
            ol: MarkdownOrderedList,
            ul: MarkdownUnorderedList,
            li: MarkdownListItem,
            img: MarkdownImage,
            table: MarkdownTable,
            hr: MarkdownHr,
          },
        }}>
        {text}
      </Markdown>
    </div>
  );
};
