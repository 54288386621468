.launchStyle {
  width: 92%;
  min-height: 93vh;
  padding: 0 2%;
  margin: 0 2%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.textWrapper {
  width: 70vw;
  margin: 0 15%;
}

.stacks {
  width: 80vw;
  margin: 1% 10%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
