import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const BLogoStyle = styled.div`
  padding: 0 0.5rem;
  
  .wrapper {
    ${({ clickable }) => {
      if (clickable) return `cursor: pointer;`;
    }}

  .logo {
    width: 1.7rem;
    content: url(${({ url }) => url});
  }
`;

export const Logo = ({ clickable }) => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <BLogoStyle url={theme.logo} clickable={clickable}>
      <div
        className={'wrapper'}
        onClick={clickable ? () => history.push('/') : undefined}>
        <section className={'logo'} />
      </div>
    </BLogoStyle>
  );
};

Logo.propTypes = {
  clickable: PropTypes.bool,
};

Logo.defaultProps = {
  clickable: true,
};
