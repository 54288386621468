import React from 'react';
import * as typography from '../../../styles/typography.module.scss';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../../state/atoms';

export const MarkdownP = ({ children }) => {
  const theme = useRecoilValue(themeAtom);

  return (
    <section
      className={`${typography.typeface} ${typography[theme]} ${typography.line}`}>
      <p>{children}</p>
    </section>
  );
};
